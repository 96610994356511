<template>
    <div class="flex justify-content-center align-items-center pb-1">
        <div class="d-flex justify-content-between">
            <small>Qtde total de produtos: {{totalProdutos}}</small>
            <button class="btn btn-sm btn-outline-success mb-2" @click="adicionarProduto">Novo Produto</button>
        </div>

        <b-table-lite striped
                      bordered
                      details-td-class="align-middle"
                      v-model="produtoAberto"
                      :items="produtos"
                      :fields="itemsProdutoExibicao">

            <template #cell(images)="data">
                <img :src="data.value[0]"
                     width="60" />
            </template>

            <template #cell(price)="{ item }">
                {{ parseFloat(item.price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}
            </template>

            <template #cell(status)="data">
                <span class="d-block">
                    {{data.item.status === 'enabled' ? 'Habilitado' : 'Desabilitado' }}
                    <i type="button"
                       class="fas fa-pen fa-xs ml-1"
                       @click="atualizarStatusProduto(data.index)"></i>
                </span>
            </template>

            <template v-slot:cell(botoes)="{ item }">
                <div class="d-flex justify-content-around align-items-center">
                    <i type="button" class="fas fa-eye" @click="toggleDetails(item)"></i>
                    <i type="button" class="fas fa-trash" @click="removerProduto(item)"></i>
                </div>
            </template>

            <template #row-details="data">
                <div class="text-right">
                    <i type="button"
                       class="fas fa-edit btn-lg p-2"
                       v-if="!edicaoProdutoHabilitado"
                       @click="habilitarEdicaoProduto"></i>

                    <div v-else>
                        <button type="button"
                                class="btn btn-sm btn-secondary mb-2 mr-2"
                                @click="habilitarEdicaoProduto(false)">
                            Cancelar
                        </button>
                        <button type="button"
                                class="btn btn-sm btn-success mb-2"
                                @click="salvarProduto(data.index, data.item)">
                            Salvar
                        </button>
                    </div>
                </div>

                <div>
                    <b-form id="form-produto" class="formulario-edicao-produto">
                        <div class="w-50 pr-5">
                            <label class="d-flex justify-content-between">
                                <span>Nome</span>
                                <input type="text" v-model="produto.name" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>SKU</span>
                                <input type="text" v-model="produto.sku" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Quantidade</span>
                                <input type="number" v-model="produto.qty" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Descrição</span>
                                <textarea type="text" v-model="produto.description" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Preço</span>
                                <input type="text"
                                       placeholder="0,00"
                                       v-model.lazy="produtoPrice"
                                       v-money="maskMoney"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Preço Promocional</span>
                                <input type="text"
                                       placeholder="0,00"
                                       v-model.lazy="produtoPromotionalPrice"
                                       v-money="maskMoney"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Custo</span>
                                <input type="text"
                                       placeholder="0,00"
                                       v-model.lazy="produtoCost"
                                       v-money="maskMoney"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Status</span>
                                <select type="text" v-model="produto.status" disabled>
                                    <option value="enabled">Habilitado</option>
                                    <option value="disabled">Desabilitado</option>
                                </select>
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Peso <small>(kg)</small></span>
                                <input type="text" v-model="produto.weight" v-money="maskWeight" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Comprimento <small>(cm)</small></span>
                                <input v-model="produto.length"
                                       v-mask="['##', '##.##', '###.##', '####.##', '#####.##', '######.##', '#######.##', '########.##']"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Largura <small>(cm)</small></span>
                                <input v-model="produto.width"
                                       v-mask="['##', '##.##', '###.##', '####.##', '#####.##', '######.##', '#######.##', '########.##']"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Altura <small>(cm)</small></span>
                                <input v-model="produto.height"
                                       v-mask="['##', '##.##', '###.##', '####.##', '#####.##', '######.##', '#######.##', '########.##']"
                                       disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>NBM</span>
                                <input type="text" v-model="produto.nbm" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>EAN</span>
                                <input type="text" v-model="produto.ean" disabled />
                            </label>
                            <label class="d-flex justify-content-between">
                                <span>Marca</span>
                                <input type="text" v-model="produto.brand" disabled />
                            </label>

                            <div class="d-flex justify-content-between">
                                <label>
                                    Categorias
                                </label>
                                <div class="caixa-categorias-produto d-inline-block border p-2">
                                    <div v-show="edicaoProdutoHabilitado">
                                        <label class="d-flex">
                                            <input v-model="inputCategoria"
                                                   list="categoriasCadastradas"
                                                   type="text"
                                                   placeholder="Categoria > Subcategoria..."
                                                   disabled />
                                            <button type="button"
                                                    class="btn btn-sm btn-info ml-1"
                                                    @click="addCategoriaProduto(data.index, inputCategoria)">
                                                +
                                            </button>
                                        </label>

                                        <datalist id="categoriasCadastradas">
                                            <option v-for="categoria in categorias"
                                                    :key="categoria.code"
                                                    :value="categoria.name">
                                                {{ categoria.name }}
                                            </option>
                                        </datalist>

                                    </div>
                                    <div class="d-flex flex-wrap">
                                        <small class="mb-1 mr-1 px-1 rounded text-white bg-secondary text-nowrap"
                                               v-for="(categoria, index) in produto.categories"
                                               :key="index">
                                            {{categoria.name}}
                                            <span class="btn-remove bg-white text-dark rounded-circle px-1 ml-1"
                                                  v-if="edicaoProdutoHabilitado"
                                                  @click="produto.categories.splice(index, 1)">
                                                x
                                            </span>
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="produto-imagens d-flex justify-content-between mt-2">
                                <label>
                                    Imagens
                                </label>
                                <div class="caixa-imagens-produto border p-2">
                                    <div v-if="produto.images.length > 0"
                                         class="d-flex align-items-center">
                                        <div class="d-flex flex-column align-items-center justify-content-around mx-1"
                                             v-for="(image, index) in produto.images">
                                            <i type="button"
                                               class="fas fa-trash fa-xs mb-1"
                                               v-if="edicaoProdutoHabilitado"
                                               @click="produto.images.splice(index, 1)"></i>
                                            <img :src="image"
                                                 :style="{ opacity: image.match('blob') ? '.5' : '' }"
                                                 width="75" />
                                        </div>
                                        <label for="file-input">
                                            <i type="button"
                                               class="fas fa-plus-circle fa-2x mx-3"></i>
                                            <input id="file-input"
                                                   type="file"
                                                   accept="image/*"
                                                   multiple
                                                   class="d-none"
                                                   @change="mostrarNovasImagensProduto($event, data.index)" />
                                        </label>
                                    </div>
                                    <div v-else>
                                        <p v-if="!edicaoProdutoHabilitado">Nenhuma imagem encontrada</p>
                                        <label v-else for="file-input">
                                            <i type="button"
                                               class="fas fa-plus-circle fa-2x"></i>
                                            <input id="file-input"
                                                   type="file"
                                                   accept="image/*"
                                                   multiple
                                                   class="d-none"
                                                   @change="mostrarNovasImagensProduto($event, data.index)" />
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="w-50 overflow-auto">
                            <div class="d-flex align-items-center mb-3">
                                <h5 class="text-secondary m-0">Especificações</h5>
                                <button type="button"
                                        class="btn btn-sm btn-outline-info ml-2"
                                        v-if="edicaoProdutoHabilitado"
                                        @click="addSpecificacaoProduto(data.index)">
                                    +
                                </button>
                            </div>
                            <div v-for="spec in produto.specifications">
                                <div class="d-flex justify-content-between mb-2">
                                    <input type="text"
                                           placeholder="Chave"
                                           class="input-key-specification"
                                           v-model="spec.key"
                                           list="atributos" />
                                    <datalist id="atributos">
                                        <option v-for="atributo in atributos"
                                                :key="atributo.name"
                                                :value="atributo.name" />
                                    </datalist>

                                    <div class="mx-1"></div>

                                    <input type="text" v-model="spec.value" />
                                </div>
                            </div>
                        </div>

                    </b-form>

                    <section class="variacoes-produto mt-3" v-if="produto.variations">
                        <button type="button"
                                class="btn btn-sm btn-outline-info"
                                @click="mostrarVariacoesProduto(produto.variation_attributes)">
                            <span class="mr-2">Variações</span>
                            <i type="button"
                               class="align-middle fas"
                               :class="variacoesProdutoVisivel ? 'fa-angle-up' : 'fa-angle-down'">
                            </i>
                        </button>

                        <div v-show="variacoesProdutoVisivel">
                            <TabelaVariacoesProduto :itemsTabela="itemsVariacoesProdutoCompleto"
                                                    :variacoesProduto="data.item.variations"
                                                    :edicaoHabilitada="!!edicaoProdutoHabilitado"
                                                    :atributos="atributos"
                                                    @salvarVariacao="salvarVariacao" />
                        </div>
                    </section>

                </div>
            </template>

        </b-table-lite>

        <div v-if="produtos.length < totalProdutos" class="w-100 text-right mb-1">
            <b-button size="sm" variation="primary" @click="$emit('atualizarProdutos')">Ver mais</b-button>
        </div>

    </div>
</template>

<script>
    import { VMoney } from 'v-money'
    import { mask } from 'vue-the-mask'
    import SharkHubServices from '@/assets/js/services/SharkHubServices.js'

    import TabelaVariacoesProduto from '@/components/skyhub/TabelaVariacoesProdutoSkyHub.vue'

    export default {
        name: 'TabelaProdutosSkyHub',
        directives: { money: VMoney, mask },
        components: {
            TabelaVariacoesProduto
        },
        props: {
            totalProdutos: {
                type: Number,
                default: () => 0
            },
            produtos: {
                type: Array,
                default: () => []
            },
            categorias: {
                type: Array,
                default: () => []
            },
            atributos: {
                type: Array,
                default: () => []
            }
        },
        data: function () {
            return {
                produto: {
                    name: '',
                    sku: '',
                    description: '',
                    status: 'enabled',
                    removed: false,
                    brand: '',
                    nbm: '',
                    ean: null,
                    condition_type: null,
                    price: 0,
                    promotional_price: null,
                    cost: 0,
                    qty: 0,
                    height: 0,
                    weight: 0,
                    width: 0,
                    length: 0,
                    images: [],
                    categories: [],
                    specifications: [],
                    variations: [],
                    variation_attributes: [],
                    associations: []
                },
                produtoAberto: [],
                itemsProdutoExibicao: [
                    {
                        key: 'images',
                        label: 'Imagem',
                        class: 'p-1 width-60'
                    },
                    {
                        key: 'name',
                        label: 'Nome',
                        class: 'p-1'
                    },
                    {
                        key: 'sku',
                        label: 'SKU',
                        class: 'p-1'
                    },
                    {
                        key: 'description',
                        label: 'Descrição',
                        class: 'p-1'
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        class: 'p-1'
                    },
                    {
                        key: 'qty',
                        label: 'Qtde',
                        class: 'p-1'
                    },
                    {
                        key: 'price',
                        label: 'Preço',
                        class: 'p-1'
                    },
                    {
                        key: 'botoes',
                        label: '',
                        class: 'p-1 align-middle'
                    }
                ],
                itemsVariacaoProdutoPadrao: [
                    {
                        key: 'images',
                        label: 'Imagens',
                    },
                    {
                        key: 'sku',
                        label: 'SKU',
                    },
                    {
                        key: 'ean',
                        label: 'EAN',
                    },
                    {
                        key: 'price',
                        label: 'Preço',
                    },
                    {
                        key: 'promotional_price',
                        label: 'Preço Promocional',
                    },
                    {
                        key: 'qty',
                        label: 'Qtde',
                    },
                ],
                variacoesProdutoVisivel: false,
                itemsVariacoesProdutoCompleto: [],
                edicaoProdutoHabilitado: false,

                inputCategoria: '',

                maskMoney: {
                    thousands: '.',
                    decimal: ',',
                    precision: 2,
                },
                maskWeight: {
                    thousands: '.',
                    decimal: '.',
                    precision: 3,
                },
            }
        },
        computed: {
            produtoPrice: {
                get: ({ produto }) => produto.price ? produto.price.toFixed(2) : 0,
                set: function (value) { this.produto.price = this.formatarPreco(value) }
            },
            produtoPromotionalPrice: {
                get: ({ produto }) => produto.promotional_price ? produto.promotional_price.toFixed(2) : 0,
                set: function (value) { this.produto.promotional_price = this.formatarPreco(value) }
            },
            produtoCost: {
                get: ({ produto }) => produto.cost ? produto.cost.toFixed(2) : 0,
                set: function (value) { this.produto.cost = this.formatarPreco(value) }
            },
        },
        methods: {
            habilitarEdicaoProduto(disabled = true) {
                $(`#form-produto`).find("input, textarea, select").attr("disabled", !disabled);
                $(`#form-variacao-produto`).find("input, textarea, select").attr("disabled", !disabled);
                this.edicaoProdutoHabilitado = disabled;
                this.removerSpecificacoesVazias();
            },
            adicionarProduto() {
                this.limparProduto();

                this.produto['novoProduto'] = true;
                this.produtos.push(this.produto);

                this.produto['showDetails'] = false;
                this.toggleDetails(this.produto);
            },
            identificarImagensAdicionadas(imagens, imagensAdicionadas) {
                return imagensAdicionadas ?
                    imagensAdicionadas.filter(novaImagem => imagens.includes(novaImagem.urlBlob))
                    : []
            },
            atualizarStatusProduto(indiceProduto) {
                const { sku, status } = this.produtos[indiceProduto];
                let statusAlteracao = status === 'enabled' ? 'disabled' : 'enabled'
                let response = SharkHubServices.alterarStatusProdutoSkyHub(sku, statusAlteracao);
                response.then(res => {
                    console.log('status produto: ', res)
                    if (res.success)
                        this.produtos[indiceProduto].status = statusAlteracao
                })
            },
            salvarProduto(indiceProduto, produto) {
                let descricaoParagradosArray = produto.description.split('\n')
                descricaoParagradosArray = descricaoParagradosArray.filter(Boolean)
                descricaoParagradosArray = descricaoParagradosArray.map(paragrafo => `<p>${paragrafo}</p>`)

                produto = {
                    ...produto,
                    description: descricaoParagradosArray.join(''),
                    imagensAdicionadas: this.identificarImagensAdicionadas(produto.images, produto.imagensAdicionadas)
                }

                if (!this.validarCamposProduto(produto))
                    return;

                if (produto.variations?.length) {
                    let variacoesProdutoPrecoFormatado = this.formatarPrecosVariacoes(produto.variations);
                    let atributosVariacoes = this.buscarAtributosVariacoes(produto.variations);

                    produto.variations.forEach((variacao, index) => {
                        if (variacao.imagensAdicionadas) {
                            const imgsAdicionadas = this.identificarImagensAdicionadas(variacao.images, variacao.imagensAdicionadas);
                            produto.variations[index].imagensAdicionadas = imgsAdicionadas;
                            console.log('img add: ', produto.variations[index].imagensAdicionadas)
                        }
                    })


                    produto = {
                        ...produto,
                        variations: variacoesProdutoPrecoFormatado,
                        variation_attributes: atributosVariacoes
                    }
                }


                this.$emit('salvarProduto', produto)

                if (produto.novoProduto) {
                    setTimeout(() => {
                        delete this.produtos[indiceProduto].novoProduto;
                        this.produtos[indiceProduto].skuInicial = produto.sku;
                    }, 2000)
                }

                this.habilitarEdicaoProduto(false);
            },
            validarCamposProduto(produto) {
                console.log('validacao: ', produto)

                let mensagensCamposInvalidos = [];

                if (!produto.name)
                    mensagensCamposInvalidos.push('Nome deve ser preenchido')

                if (!produto.sku || (/[^0-9a-zA-Z]/.test(produto.sku))) {
                    mensagensCamposInvalidos.push('SKU deve ser numérica ou alfanumérica')
                }

                if (!produto.description) {
                    mensagensCamposInvalidos.push('Descrição deve ser preenchido')
                }

                if (!produto.weight) {
                    mensagensCamposInvalidos.push('Peso deve ser preenchido')
                }

                if (!produto.height) {
                    mensagensCamposInvalidos.push('Altura deve ser preenchido')
                }

                if (!produto.width) {
                    mensagensCamposInvalidos.push('Largura deve ser preenchido')
                }

                if (!produto.length) {
                    mensagensCamposInvalidos.push('Comprimento deve ser preenchido')
                }

                if (produto.categories.length === 0) {
                    mensagensCamposInvalidos.push('Categoria deve ser adicionada')
                }

                if (produto.images.length === 0 && produto.imagensAdicionadas.length === 0) {
                    mensagensCamposInvalidos.push('Imagem deve ser adicionada')
                }

                if (mensagensCamposInvalidos.length !== 0) {
                    alert(mensagensCamposInvalidos.join('\n'))
                    return false;
                }
                return true;
            },
            formatarPreco(valor) {
                return parseFloat(valor.replace(/[.]/g, '').replace(',', '.'))
            },
            formatarPeso() {
                this.produto.weight = this.produto.weight.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 3 });
            },
            formatarDimensoes() {
                const dimensoes = ['height', 'width', 'length'];
                dimensoes.forEach(dimensao => {
                    let dimensaoTipoInteiro = Number.isInteger((parseFloat(this.produto[dimensao])));
                    if (!dimensaoTipoInteiro) {
                        this.produto[dimensao] = this.produto[dimensao].toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
                    }
                })
            },
            limparProduto() {
                this.produto = {
                    name: '',
                    sku: '',
                    description: '',
                    status: 'enabled',
                    removed: false,
                    brand: 'Marca',
                    nbm: '1234567890123',
                    ean: '1234567890123',
                    condition_type: null,
                    price: 0,
                    promotional_price: null,
                    cost: 0,
                    qty: 0,
                    height: 0,
                    length: 0,
                    weight: 0,
                    width: 0,
                    images: [],
                    categories: [],
                    specifications: [],
                    variations: [],
                    variation_attributes: [],
                    associations: []
                }
            },
            toggleDetails(row) {
                if (row._showDetails) {
                    this.$set(row, '_showDetails', false)
                } else {
                    this.produtoAberto.forEach(item => {
                        this.$set(item, '_showDetails', false)
                    })

                    this.$nextTick(() => {
                        this.produto = row;
                        this.formatarPeso();
                        this.formatarDimensoes();
                        delete this.produto._showDetails;
                        this.removerSpecificacoesVazias();
                        setTimeout(() => { this.habilitarEdicaoProduto(row?.novoProduto ? true : false) }, 300);
                        this.$set(row, '_showDetails', true)
                    })
                }
            },
            removerProduto(produto) {
                var confirmaExclusaoProduto = confirm(`Remover este produto (${produto.sku})?`);
                if (confirmaExclusaoProduto) {
                    this.$emit('removerProduto', produto.sku);
                }
            },
            addCategoriaProduto(indiceProduto, novaCategoria) {
                if (!novaCategoria)
                    return;

                let categoriaCadastrada = this.categorias.find(x => x.name === novaCategoria);

                if (!this.produtos[indiceProduto].categories.find(x => x.name === novaCategoria)) {
                    this.produtos[indiceProduto].categories
                        .push(categoriaCadastrada ? categoriaCadastrada : { code: '', name: novaCategoria });
                }

                this.inputCategoria = '';
            },
            mostrarNovasImagensProduto(event, indiceProduto) {
                let imagens = event.target.files
                console.log(imagens);

                if (imagens.length > 0) {
                    if (!this.produtos[indiceProduto].imagensAdicionadas) {
                        this.produtos[indiceProduto].imagensAdicionadas = [];
                    }

                    for (let imagem of imagens) {
                        if (imagem.type.split('/')[0] === 'image') {
                            let imagemUrl = URL.createObjectURL(imagem);
                            this.produtos[indiceProduto].images.push(imagemUrl);
                            this.produtos[indiceProduto].imagensAdicionadas.push({ arquivo: imagem, urlBlob: imagemUrl })
                        }
                    }
                }
            },
            addSpecificacaoProduto(indiceProduto) {
                this.produtos[indiceProduto].specifications.push({ key: '', value: '' });
            },
            removerSpecificacoesVazias() {
                this.produto.specifications = this.produto.specifications.filter(spec => spec.key);
            },
            mostrarVariacoesProduto(atributosVariacoes) {
                this.itemsVariacoesProdutoCompleto = this.itemsVariacaoProdutoPadrao
                    .concat(atributosVariacoes.map(atributo => { return { key: atributo } }));

                this.itemsVariacoesProdutoCompleto.push({ key: 'botoes', label: ' ' })
                this.itemsVariacoesProdutoCompleto.forEach(item => item['class'] = 'p-1 align-middle')

                this.variacoesProdutoVisivel = !this.variacoesProdutoVisivel;
            },
            salvarVariacao(variacaoProduto) {
                this.$emit('salvarVariacao', variacaoProduto)
                this.habilitarEdicaoProduto(false);
            },
            formatarPrecosVariacoes(variacoes) {
                return variacoes
                    .map(variation => {
                        let specificationsFormatadas = variation.specifications
                            .map(spec => {
                                if (['price', 'promotional_price'].includes(spec.key)) {
                                    return { ...spec, value: this.formatarPreco(spec.value).toString() }
                                }
                                return spec
                            })

                        return { ...variation, specifications: specificationsFormatadas }
                    })
            },
            buscarAtributosVariacoes(variacoesProduto) {
                const ATRIBUTOS_DESCARTADOS = ['price', 'promotional_price']

                let atributos = [];
                variacoesProduto.forEach(variacao => {
                    variacao.specifications.forEach(spec => {
                        if (!atributos.includes(spec.key) && !ATRIBUTOS_DESCARTADOS.includes(spec.key))
                            atributos.push(spec.key);
                    })
                })
                return atributos
            }
        },
    };

</script>

<style scoped>
    .button-menu {
        color: white;
        font-size: 14px;
        min-width: 60px;
        width: 8rem;
        text-align: center;
        border-radius: 0;
    }

    .formulario-edicao-produto {
        display: flex;
    }

        .formulario-edicao-produto input, select, textarea {
            width: 25vw;
        }

    .caixa-categorias-produto {
        max-height: 100px;
        width: 25vw;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .btn-remove:hover {
        cursor: pointer;
        opacity: .75;
    }

    .caixa-imagens-produto {
        width: 25vw;
        min-height: 90px;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    .input-key-specification {
        border: none;
        border-bottom: 1px solid;
    }

        .input-key-specification:disabled {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid;
        }
</style>

<style>
    .width-60 {
        width: 60px;
    }
</style>
