<template>
    <div>
        <!-- Modal -->
        <Breadcrumb titulo="Configurações" :items="itensBreadcrumb" backgroundColor="#1d1368" />
        <div id="container_configuracoes" class="container-fluid container_configuracoes m-4">

            <div class="row h-100">
                <div class="col-2 h-100 text-center p-0">
                    <h4 class="bg-secondary text-white m-0 py-2">Integrações</h4>
                    <div class="menu-list d-flex flex-column h-100">
                        <button class="menu-button border-bottom"
                                v-for="canal in canalIntegracoes"
                                :key="canal.nome"
                                :class="{ 'bg-omni-cinza-3 font-weight-bold': mostrarCanalAberto(canal.nome) }"
                                @click="openMenu(canal)">
                            {{canal.nome}}
                        </button>
                    </div>
                </div>

                <div class="wrapper-conteudo-integracao col-9 h-100 py-5 overflow-auto">
                    <div v-if="mostrarCanalAberto('Facebook')">
                        <div v-if="canalIntegracaoHabilitado" class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">
                                Ao efetuar seu login, é necessario autorizar as permissões do Facebook solicitadas para
                                termos acesso ao gerenciamento de mensagens de sua página do Facebook.
                            </p>
                            <button id="botao-login-facebook"
                                    @click="loginFacebook">
                                LOGIN FACEBOOK
                            </button>
                            <p id="msgLoginFacebook" class="d-none mt-4"></p>
                            <div v-if="paginasFacebookAtiva.length" class="py-3">
                                <b-table-simple class="mt-5" caption-top bordered>
                                    <caption>Páginas do Facebook ({{paginasFacebookAtiva.length}})</caption>
                                    <b-thead>
                                        <b-th>Nome</b-th>
                                        <b-th>Facebook ID</b-th>
                                        <b-th>Configurado em</b-th>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="paginaFacebook in paginasFacebookAtiva"
                                              :key="paginaFacebook.paginaFacebookId">
                                            <b-th>{{paginaFacebook.nomeFacebook}}</b-th>
                                            <b-td>{{paginaFacebook.paginaFacebookId}}</b-td>
                                            <b-td>{{dataHoraFormata(paginaFacebook.dataCriacao)}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">NÃO HABILITADO PARA ESTA PLATAFORMA</p>
                        </div>
                    </div>

                    <div v-if="mostrarCanalAberto('Instagram')">
                        <div v-if="canalIntegracaoHabilitado"
                             class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">
                                Ao efetuar login, é necessario autorizar as permissões do Facebook
                                solicitadas para termos acesso ao gerenciamento de mensagens da página do
                                Instagram que está vinculada à página do Facebook.
                            </p>
                            <button id="botao-login-facebook"
                                    @click="loginFacebookParaConectarInstagram">
                                LOGIN INSTAGRAM
                            </button>
                            <p id="msgLoginInstagram" class="d-none mt-3"></p>
                            <div v-if="paginasInstagramAtiva.length" class="py-4">
                                <b-table-simple class="mt-5" caption-top bordered>
                                    <caption>Páginas do Instagram ({{paginasInstagramAtiva.length}})</caption>
                                    <b-thead>
                                        <b-th>Nome</b-th>
                                        <b-th>Instagram ID</b-th>
                                        <b-th>Configurado em</b-th>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="paginaInstagram in paginasInstagramAtiva"
                                              :key="paginaInstagram.paginaInstagramId">
                                            <b-th>{{paginaInstagram.nomeInstagram}}</b-th>
                                            <b-td>{{paginaInstagram.paginaInstagramId}}</b-td>
                                            <b-td>{{dataHoraFormata(paginaInstagram.dataCriacao)}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">
                                NÃO HABILITADO PARA ESTA PLATAFORMA
                            </p>
                        </div>
                    </div>

                    <div v-if="mostrarCanalAberto('Mercadolivre')">
                        <div v-if="canalIntegracaoHabilitado"
                             class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">
                                <img src="@/assets/Logos/ml_logo.png" style="width: 100px" />
                            </p>
                            <p class="text-center px-5">
                                Autorize sua conta do Mercado Livre para termos acesso as perguntas e
                                mensagens de pós vendas.
                            </p>
                            <button class="btn btn-outline-primary mb-5"
                                    @click="autorizarContaMercadolivre">
                                Autorizar
                            </button>
                            <div v-if="contasMercadolivreAutorizadas.length" class="w-50 text-center">
                                <b-table-simple class="mt-5 text-left" caption-top bordered>
                                    <caption>Contas do Mercadolivre autorizadas ({{contasMercadolivreAutorizadas.length}})</caption>
                                    <b-thead>
                                        <b-th>Nome</b-th>
                                        <b-th>Conta ID</b-th>
                                        <b-th>Configurado em</b-th>
                                        <b-th>Data expiração</b-th>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="contaMercadolivre in contasMercadolivreAutorizadas"
                                              :key="contaMercadolivre.contaId">
                                            <b-th>{{contaMercadolivre.nomeConta}}</b-th>
                                            <b-td>{{contaMercadolivre.contaId}}</b-td>
                                            <b-td>{{dataHoraFormata(contaMercadolivre.dataCriacao)}}</b-td>
                                            <b-td>{{dataHoraFormata(contaMercadolivre.dataExpiracaoLogin)}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                            <div v-else>
                                <p>NENHUMA CONTA DO MERCADO LIVRE ENCONTRADA</p>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">
                                NÃO HABILITADO PARA ESTA PLATAFORMA
                            </p>
                        </div>
                    </div>

                    <div v-if="mostrarCanalAberto('Shopee')">
                        <div v-if="canalIntegracaoHabilitado"
                             class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">
                                Autorize sua conta Shopee para termos acesso ao chat de sua conta.
                            </p>
                            <button class="btn btn-outline-primary mb-5" @click="autorizarContaShopee">
                                Autorizar
                            </button>

                            <div v-if="contaShopeeAutorizada" class="w-100 text-center">
                                <p>
                                    Conta do Shopee autorizada:
                                    <strong>{{ contaShopeeAutorizada.nomeConta }}</strong>
                                </p>
                                <small class="d-block">
                                    Data expiração do acesso:
                                    {{ contaShopeeAutorizada.dataExpiracaoLogin }}
                                </small>
                                <small class="d-block">
                                    Data expiração token de reautorização:
                                    {{ contaShopeeAutorizada.dataExpiracaoRefreshToken }}
                                </small>
                            </div>
                            <div v-else>
                                <p>NENHUMA CONTA SHOPEE ENCONTRADA</p>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">
                                NÃO HABILITADO PARA ESTA PLATAFORMA
                            </p>
                        </div>
                    </div>

                    <div v-if="mostrarCanalAberto('SMSInfoBip')">
                        <div v-if="SMSInfoBipDados == undefined">
                            Para Habilitar o serviço de SMS Infobip, você deve entrar em contato com o comercial SharkData.
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col">
                                    <img src="https://www.infobip.com/wp-content/themes/infobip/static/ui/infobip-logo.svg" style="width:9%" />
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="col-3">
                                    <input-select class="mr-4 mt-1"
                                                  label="Habilitar uso SMS?"
                                                  v-model.trim="opcaoSMSSelecionado"
                                                  :opcoes="[
                                                { text: 'Sim', value: 1 },
                                                { text: 'Não', value: 0 }]" />
                                </div>
                                <div class="col">
                                    <div style="position: relative;  bottom: -6px;">
                                        <input-padrao v-if="opcaoSMSSelecionado==1" class="mt-2" label="Token APP:" v-model.trim="TokenSMSInfobip" />
                                    </div>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="col">
                                    <botao-modal-ok type="button" class="float-right ml-2 btn-sm" @click="salvarSMS">
                                        Salvar
                                    </botao-modal-ok>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-if="mostrarCanalAberto('GoogleDrive')">
                        <div>Em Desenvolvimento</div>
                    </div>
                    <div v-if="mostrarCanalAberto('NuvemShop')">

                        <div v-if="canalIntegracaoHabilitado"
                             class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">
                                <img src="@/assets/Logos/logo_nuvemShop.png" id="img-fundo" />
                            </p>
                            <div v-if="nuvemShopDados == undefined">
                                <button id="btn_gerar_nuvem_shop" v-on:click="gerar_modulo('NuvemShop')">
                                    Gerar modulo
                                </button>
                            </div>
                            <div v-else>
                                <span class="caixa_aviso_modulos">
                                    <i class="fas fa-exclamation-triangle" style="color: #fbe80b"></i>
                                    Para autorizar a SharkData acessar as informações da NuvemShop você deve
                                    clicar em conectar;<br />
                                    - após isso irá abrir uma outra janela em seu navegado para realizar o
                                    login e autorizar;<br />
                                    - Cole o Id da loja e clique em sincronizar.<br />
                                    Feito isso seu sistema estará pronto para realizar as consultas em cada
                                    atendimento.
                                </span>
                                <div id="passo1_nuvem_shop"
                                     class="caixa_passos"
                                     v-if="nuvemShopDados.disponivel && parametrosModulos.length == 0">
                                    <label class="titulo_passos">PASSO 1:</label><br />
                                    <button type="button"
                                            class="btn btn-success btn_conectar_nuvemshop"
                                            v-on:click="conectar_modulo('NuvemShop')">
                                        Conectar
                                    </button>
                                </div>
                                <div id="passo2_nuvem_shop"
                                     class="caixa_passos"
                                     v-if="nuvemShopDados.disponivel && parametrosModulos.length == 0">
                                    <label class="titulo_passos">PASSO 2:</label>
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               class="form-control"
                                               placeholder="Id da loja"
                                               aria-label="Id da loja"
                                               aria-describedby="basic-addon2"
                                               v-model="nuvemShopId" />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary btn_conectar_nuvemshop"
                                                    type="button"
                                                    v-on:click="sincronizar_modulo('NuvemShop')">
                                                Sincronizar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="caixa_passos"
                                     v-if="nuvemShopDados.disponivel && parametrosModulos.length > 0">
                                    <span style="color: green; font-size: 15px"> Você esta conectado!</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">
                                NÃO HABILITADO PARA ESTA PLATAFORMA
                            </p>
                        </div>
                    </div>

                    <div v-if="mostrarCanalAberto('SkyHub')">
                        <div v-if="canalIntegracaoHabilitado"
                             class="d-flex flex-column justify-content-center align-items-center">
                            <p class="text-center px-5">Autorizado</p>
                            <!--<button @click="modalSkyhubVisivel = true">Ver painel</button>
                            <h6>EM DESENVOLVIMENTO</h6>-->
                        </div>
                        <div v-else>
                            <p class="text-center font-weight-bold">
                                NÃO HABILITADO PARA ESTA PLATAFORMA
                            </p>
                        </div>
                    </div>

                </div>

            </div>

            <ModalPrincipalSkyHub v-if="modalSkyhubVisivel"
                                  @close="modalSkyhubVisivel = false" />

        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters, mapActions } from "vuex";

    import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
    import ModalPrincipalSkyHub from "@/components/skyhub/ModalPrincipalSkyHub.vue";

    import sharkHubServices from "@/assets/js/services/SharkHubServices";
    import ModulosServices from "@/assets/js/services/ModulosServices";
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';

    export default {
        name: "ConfiguracoesPage",
        components: {
            Breadcrumb,
            ModalPrincipalSkyHub,
            InputPadrao,
            InputSelect, BotaoModalOk
        },
        data: function () {
            return {
                habilitarLeads: false,
                itensBreadcrumb: [
                    {
                        id: "1",
                        classe: "fas fa-cogs",
                        texto: "Cadastros Básicos",
                        link: "/cadastros-basicos",
                    },
                    {
                        id: "2",
                        classe: "fas fa-cogs",
                        texto: "Configurações",
                        link: "",
                    },
                ],
                canalIntegracoes: [
                    { nome: "Facebook", valorTipoCanal: 4 },
                    { nome: "Instagram", valorTipoCanal: 10 },
                    { nome: "Mercadolivre", valorTipoCanal: 12 },
                    { nome: "Shopee", valorTipoCanal: 14 },
                    { nome: "SkyHub", valorTipoCanal: 16 },
                    { nome: "NuvemShop", valorTipoCanal: -1 },
                    { nome: "SMSInfoBip", valorTipoCanal: -2 },
                    { nome: "GoogleDrive", valorTipoCanal: -3 },
                    // // // { nome: "SMSGupShark", valorTipoCanal: -3 },
                ],
                canalSelecionado: { nome: "Facebook", valorTipoCanal: 4 },

                qrcode: "",
                heruku: true,
                paginasFacebookAtiva: [],
                paginasInstagramAtiva: [],
                contasMercadolivreAutorizadas: [],
                contaShopeeAutorizada: "",
                userSharkdata: "",
                nuvemShopDados: "",
                SMSInfoBipDados: "",
                parametrosModulos: [],
                nuvemShopId: "",
                modalSkyhubVisivel: false,
                opcaoSMSSelecionado: '',
                TokenSMSInfobip: null,
            }
        },

        computed: {
            ...mapGetters({
                dadosUsuarioAutenticado: "authentication/authDadosUsuario",
                ModulosDisponiveis: "modulos_integracoes/modulosItegracoes",
                getUsuarioPorId: "usuarios/getUsuarioById",
                TiposCanaisHabilitados: "canais/TiposCanaisHabilitados",
                loading: "loadingGlobal",
            }),
            usuario: function () {
                if (this.dadosUsuarioAutenticado) {
                    let user = this.getUsuarioPorId(this.dadosUsuarioAutenticado.usuarioId);
                    this.isSharkUser();
                    return user;
                }
                return {};
            },
            canalIntegracaoHabilitado() {
                return this.verificaCanalHabilitado(this.canalSelecionado.valorTipoCanal);
            }
        },

        methods: {
            ...mapActions({
                buscaModulos: "modulos_integracoes/buscaModulos",
                buscaTiposCanaisHabilitados: "canais/buscaTiposCanaisHabilitados",
            }),
            openMenu(canal) {
                this.canalSelecionado = canal;
                //NuvemShop
                this.nuvemShopDados = this.ModulosDisponiveis?.find(
                    (res) => res?.tipo === "NuvemShop"
                );
                this.parametrosModulos = this.nuvemShopDados?.parametros;
                //InfoBip
                this.SMSInfoBipDados = this.ModulosDisponiveis?.find(
                    (res) => res?.tipo === "SMSInfoBip"
                );
                this.opcaoSMSSelecionado = this.SMSInfoBipDados?.disponivel == true ? 1 : 0;
                this.TokenSMSInfobip = this.SMSInfoBipDados?.parametros[0].Valor;
                

                
            },
            mostrarCanalAberto(nomeCanal) {
                return this.canalSelecionado?.nome === nomeCanal;
            },
            verificaCanalHabilitado(tipoCanalId) {
                if (this.TiposCanaisHabilitados) {
                    if (tipoCanalId < 0) {
                        return true
                    }
                    return this.TiposCanaisHabilitados.find((x) => x.Value === tipoCanalId);
                }
                return false;
            },
            loadFacebookLoginScript() {
                if (!document.getElementById("sharkhub-facebookapi")) {
                    let elScript = document.createElement("script");
                    elScript.id = "sharkhub-facebookapi";
                    elScript.type = "text/javascript";
                    elScript.src = "https://sharkhub.azurewebsites.net/integracoes/facebook/login_facebook.js";
                    elScript.async = true;
                    elScript.defer = true;
                    document.body.append(elScript);
                }
            },
            loginFacebook() {
                let _this = this;
                setTimeout(() => {
                    SHARK_HUB.loginFacebook("facebook", function (data) {
                        let elementoMensagem = document.getElementById("msgLoginFacebook");
                        if (data.success) {
                            elementoMensagem.innerText = data.message;
                            elementoMensagem.className = "d-block text-success mt-3";
                            setTimeout(() => {
                                _this.buscarPaginasFacebookConfiguradas();
                            }, 1000);
                        } else {
                            elementoMensagem.innerText = data.message;
                            elementoMensagem.className = "d-block text-danger mt-3";
                        }
                    });
                }, 1000);
            },
            loginFacebookParaConectarInstagram() {
                let _this = this;
                setTimeout(() => {
                    SHARK_HUB.loginFacebook("instagram", function (data) {
                        let elementoMensagem = document.getElementById("msgLoginInstagram");
                        if (data.success) {
                            elementoMensagem.innerText = data.message;
                            elementoMensagem.className = "d-block text-success mt-3";
                            setTimeout(() => {
                                _this.buscarPaginasFacebookConfiguradas();
                            }, 1000);
                        } else {
                            elementoMensagem.innerText = data.message;
                            elementoMensagem.className = "d-block text-danger mt-3";
                        }
                    });
                }, 1000);
            },
            buscarPaginasFacebookConfiguradas() {
                sharkHubServices.getPaginasFacebookConfiguradas().then((res) => {
                    this.paginasFacebookAtiva = res.filter(pag => pag.tipoPagina === "facebook");
                    this.paginasInstagramAtiva = res.filter(pag => pag.tipoPagina === "instagram");
                });
            },
            autorizarContaMercadolivre() {
                const URL_AUTORIZACAO_MERCADOLIVRE = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=4567087324459834&state=${this.$hostOmni}`;
                window.open(URL_AUTORIZACAO_MERCADOLIVRE);
            },
            buscarContasMercadolivreAutorizadas() {
                sharkHubServices.getContaMercadolivreAutorizadaNoDominio().then((res) => {
                    if (res.success) {
                        res.data.dataExpiracaoLogin = this.dataHoraFormata(res?.data?.dataExpiracaoLogin);
                        this.contasMercadolivreAutorizadas = res.data;
                    }
                });
            },
            autorizarContaShopee() {
                sharkHubServices.getShopeeLinkDeAutorizacao().then((shopeeLink) => {
                    window.open(shopeeLink);
                });
            },
            buscarContaShopeeAutorizada() {
                sharkHubServices.getContaShopeeAutorizadaNoDominio()
                    .then(res => {
                        res.data.dataExpiracaoLogin = this.dataHoraFormata(res?.data?.dataExpiracaoLogin);
                        res.data.dataExpiracaoRefreshToken = this.dataHoraFormata(res?.data?.dataExpiracaoRefreshToken);
                        this.contaShopeeAutorizada = res.data;
                    });
            },
            dataHoraFormata(jsonDate) {
                return `${new Date(jsonDate).toLocaleDateString('pt-br')} ${new Date(jsonDate).toLocaleTimeString('pt-br')}`;
            },


            conectar_modulo: async function (valor) {
                if (valor == "NuvemShop") {
                    window.open("https://www.tiendanube.com/apps/3495/authorize", "_blank");
                }
            },
            sincronizar_modulo: async function (valor) {
                if (valor == "NuvemShop") {
                    let ret = await ModulosServices.carregarParametrosModulos(
                        valor,
                        this.nuvemShopId
                    );
                    this.buscaModulos();
                }
            },

            salvarSMS: function () {
                const dados = {
                    "ModuloId": this.SMSInfoBipDados.id,
                    "ValorChave": this.opcaoSMSSelecionado == 1 ? this.TokenSMSInfobip : '',
                    "Chave": "Token",
                    "Ativo": this.opcaoSMSSelecionado == 1
                }
                if (this.opcaoSMSSelecionado) {
                    if (this.TokenSMSInfobip) {
                        axios.post('/api/modulos/InserirParamModulo', dados).then(c => {
                            this.$bvToast.toast("Registro realizado com sucesso!", {
                                title: `Aviso`,
                                toaster: 'b-toaster-top-right',
                                solid: true,
                                variant: 'success',
                                appendToast: false,
                                autoHideDelay: 3000,
                                noCloseButton: true
                            });
                        })
                    }
                }


            }

        },
        mounted() {
            this.loadFacebookLoginScript();
            this.buscarPaginasFacebookConfiguradas();
            this.buscarContasMercadolivreAutorizadas();
            this.buscarContaShopeeAutorizada();
            this.openMenu(this.canalSelecionado);
        },
        created() {
            this.buscaTiposCanaisHabilitados();
        }
    }
</script>

<style scoped>
    #botao-login-facebook {
        color: white;
        background-color: #4267B2;
        padding: 5px 15px;
    }

    #botao-login-facebook {
        color: white;
        background-color: #4267B2;
        padding: 5px 15px;
    }

    #erro-login-facebook,
    #erro-login-instagram {
        display: none;
        color: red;
    }

    #btn_gerar_nuvem_shop {
        background-color: #029cdc;
        color: #fff;
        padding: 2px 10px;
        cursor: pointer;
    }

    #circulo_status {
        border-radius: 50%;
        display: inline-block;
        height: 16px;
        width: 16px;
        border: 1px solid #666;
        background-color: #ffff00;
        box-shadow: inset -2px -2px 3px #67a, inset 1px 0px 7px #fff;
    }

    #container_configuracoes {
        height: calc(100vh - 9.9em);
        overflow: hidden;
    }

        #container_configuracoes button:focus {
            outline: none;
        }

    #passo2_nuvem_shop {
        margin-top: 15px;
    }

    .card {
        height: auto !important;
    }

    .menu-list {
        background-color: #ccc;
    }

        .menu-list .menu-button {
            width: 100%;
            background-color: transparent;
            border: none;
            height: 50px;
        }

    .menu-button:hover {
        background-color: silver;
    }

    .wrapper-conteudo-integracao,
    .bg-omni-cinza-3 {
        background-color: var(--cinza-3) !important;
    }

    .btn_conectar_nuvemshop {
        background-color: #02dcc8;
        color: #fff;
        padding: 2px 10px;
        cursor: pointer;
    }

    .avisos_integracao {
        display: block;
        background: #fff;
        padding: 20px;
        color: #5e6daf;
    }

    .caixa_aviso_modulos {
        background-color: #fff;
        display: block;
        padding: 20px;
        margin-bottom: 15px;
    }

    .titulo_passos {
        font-size: 14px;
        font-weight: bold;
    }

    .caixa_passos {
        background-color: #fff;
        padding: 15px;
    }

    .input-padrao-group {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 28px;
    }



        .input-padrao-group:hover, .input-padrao:focus {
            outline: none;
        }
</style>
