<template>
    <div class="pb-1">
        <div class="d-flex justify-content-between mb-2">
            <small>Total de pedidos: {{pedidos.length}}</small>
            <button type="button"
                    class="btn btn-sm btn-outline-info"
                    @click="adicionarPedido">Novo Pedido</button>
            <button type="button" @click="atualizarFilaPedido"></button>
        </div>

        <b-table bordered
                 small
                 striped
                 ref="tabelaPedidos"
                 :fields="fieldsPedidos"
                 :items="pedidos">

            <template #cell(imported_at)="{ item }">
                {{new Date(item.imported_at).toLocaleString()}}
            </template>

            <template #cell(total_ordered)="{ item }">
                {{item.total_ordered ? parseFloat(item.total_ordered).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) : ''}}
            </template>

            <template #cell(sync_status)="{ item }">
                <small :class="[ item.sync_status === 'SYNCED' ? 'bg-success' : 'bg-warning' ]"
                       class="text-white p-1">{{ item.sync_status === 'SYNCED' ? 'Enviado' : 'Não enviado' }}</small>
            </template>

            <template #cell(botoes)="{ item }">
                <i type="button" class="fas fa-eye" @click="toggleDetails(item)"></i>
            </template>

            <template #row-details="data">
                <section id="detalhes-pedido">

                    <div class="d-flex flex-wrap w-100" style="flex: 50% 1;">

                        <div class="w-50 mb-4">
                            <h5>Origem</h5>
                            <hr class="my-2" />
                            <label class="d-block">Canal <input v-model="pedido.channel" :disabled="data.item.import_info" /></label>
                            <label class="d-block">
                                Tipo Contrato
                                <input v-model="pedido.delivery_contract_type" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">Código Remoto <input v-model="pedido.remote_code" :disabled="data.item.import_info" /></label>
                            <p v-if="data.item.import_info">Código do pedido: {{data.item.import_info.remote_code}}</p>
                        </div>
                        <div class="w-50 text-right">
                            <button type="button"
                                    class="btn btn-sm btn-success"
                                    v-if="!data.item.import_info"
                                    @click="salvarPedido(pedido)">
                                Salvar Pedido
                            </button>
                        </div>

                        <div id="form-dados-pedido" class="w-50 mb-4 pr-4">
                            <h5>Pedido</h5>
                            <hr class="my-2" />

                            <section v-if="pedido.status" class="secao-status-pedido w-75 p-2 mb-3 border border-primary rounded">

                                <p class="m-0">STATUS ATUAL: <strong>{{pedido.status.label}}</strong></p>
                                <div v-if="pedido.status.type !== 'NEW'"
                                     class="mt-2">

                                    <div class="d-flex mb-2">
                                        <span>Atualizar: </span>
                                        <select class="ml-1 border border-primary" v-model="statusPedidoAlterado">
                                            <option v-for="status in listaStatusPedido" :value="status.code">{{status.label}}</option>
                                        </select>
                                    </div>
                                    <div v-if="statusPedidoAlterado === 'order_invoiced'">
                                        <div v-if="pedido.shipping_calculation_type === 'b2wentregadirect'">
                                            <label>
                                                <span>Qtde etiquetas</span>
                                                <input class="ml-2" type="number" v-model="dadosStatus.invoice.volume_qty" />
                                            </label>
                                            <label>
                                                <span>Data emissão</span>
                                                <input class="ml-2" type="datetime-local" v-model="dadosStatus.invoice.issue_date" />
                                            </label>
                                            <label>
                                                <span class="mr-1">Arquivo <small>(XML)</small></span>
                                                <input type="file" accept="text/xml" ref="arquivoFaturadoDirect" />
                                            </label>
                                        </div>
                                        <label v-else>
                                            <span>Chave NF-e</span>
                                            <input class="ml-2" style="width: 24vw;" v-model="dadosStatus.invoice.key" />
                                        </label>
                                    </div>
                                    <div v-if="statusPedidoAlterado === 'order_shipped'">
                                        <label>
                                            <span>Data de entrega (Transportadora)</span>
                                            <input class="ml-2" type="datetime-local" v-model="dadosStatus.shipments.delivered_carrier_date" />
                                        </label>
                                        <label>
                                            <span>Código de Rastreio</span>
                                            <input class="ml-2" style="width: 21vw;" v-model="dadosStatus.shipments.track.code" />
                                        </label>
                                        <label>
                                            <span>Link de Rastreio</span>
                                            <input class="ml-2" style="width: 22.5vw;" v-model="dadosStatus.shipments.track.url" />
                                        </label>
                                        <label>
                                            <span>Transportadora</span>
                                            <input class="ml-2" style="width: 23vw;" v-model="dadosStatus.shipments.track.carrier" />
                                        </label>
                                        <label>
                                            <span>Tipo de Envio</span>
                                            <input class="ml-2" style="width: 23.5vw;" v-model="dadosStatus.shipments.track.method" />
                                        </label>
                                    </div>
                                    <div v-if="statusPedidoAlterado === 'complete'">
                                        <label>
                                            <span>Data de entrega</span>
                                            <input class="ml-2" type="date" v-model="dadosStatus.delivered_date" />
                                        </label>
                                    </div>
                                    <div v-if="statusPedidoAlterado === 'shipment_exception'">
                                        <label>
                                            <span>Data de ocorrência</span>
                                            <input type="datetime-local" class="ml-2" v-model="dadosStatus.shipment_exception.occurrence_date" />
                                        </label>
                                        <label>
                                            <span>Observação</span>
                                            <input class="ml-2" style="width: 23.5vw;" v-model="dadosStatus.shipment_exception.observation" />
                                        </label>
                                    </div>

                                    <div class="w-100 text-right mt-2">
                                        <button type="button"
                                                class="btn btn-sm btn-outline-primary"
                                                @click="atualizarStatusPedido">
                                            Atualizar Status
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <label class="d-block">
                                Método de Envio
                                <input v-model="pedido.shipping_method" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Método de Cálculo
                                <input v-model="pedido.shipping_calculation_type" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Tipo de frete
                                <input v-model="pedido.shipping_carrier" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Valor do Frete
                                <input v-model="pedido.shipping_cost" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Valor do Juros
                                <input type="number" v-model="pedido.interest" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Valor do Desconto
                                <input type="number" v-model="pedido.discount" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Data da entrega estimada
                                <input type="date" v-model="pedido.estimated_delivery" :disabled="data.item.import_info" />
                            </label>
                        </div>

                        <div id="form-dados-cliente" class="w-50">
                            <h5>Cliente</h5>
                            <hr class="my-2" />
                            <label class="d-block">
                                Nome
                                <input v-model="pedido.customer.name" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Email
                                <input v-model="pedido.customer.email" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                CPF/CNPJ
                                <input type="number" v-model="pedido.customer.vat_number" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Data de nascimento
                                <input type="date" v-model="pedido.customer.date_of_birth" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Sexo
                                <select v-model="pedido.customer.gender" :disabled="data.item.import_info">
                                    <option value="female">Feminino</option>
                                    <option value="male">Masculino</option>
                                </select>
                            </label>
                            <label class="d-block">
                                Celular/Telefone
                                <input type="number"
                                       v-for="(phone, index) in pedido.customer.phones"
                                       v-model="pedido.customer.phones[index]"
                                       :disabled="data.item.import_info" />
                            </label>
                        </div>

                        <div id="form-dados-cobranca" class="w-50 pr-4">
                            <h5>Endereço de Cobrança</h5>
                            <hr class="my-2" />
                            <label class="d-block">
                                Rua
                                <input v-model="pedido.billing_address.street" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                número
                                <input v-model="pedido.billing_address.number" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Ponto de referencia
                                <input v-model="pedido.billing_address.detail" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Bairro
                                <input v-model="pedido.billing_address.neighborhood" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Cidade
                                <input v-model="pedido.billing_address.city" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                UF
                                <input v-model="pedido.billing_address.region" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                País
                                <input v-model="pedido.billing_address.country" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                CEP
                                <input v-model="pedido.billing_address.postcode" :disabled="data.item.import_info" />
                            </label>
                        </div>

                        <div id="form-dados-entrega" class="w-50">
                            <h5>Endereço de Entrega</h5>
                            <hr class="my-2" />
                            <label class="d-block">
                                Rua
                                <input v-model="pedido.shipping_address.street" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Número
                                <input v-model="pedido.shipping_address.number" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Ponto de referencia
                                <input v-model="pedido.shipping_address.detail" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Bairro
                                <input v-model="pedido.shipping_address.neighborhood" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                Cidade
                                <input v-model="pedido.shipping_address.city" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                UF
                                <input v-model="pedido.shipping_address.region" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                País
                                <input v-model="pedido.shipping_address.country" :disabled="data.item.import_info" />
                            </label>
                            <label class="d-block">
                                CEP
                                <input v-model="pedido.shipping_address.postcode" :disabled="data.item.import_info" />
                            </label>
                        </div>

                        <div class="container-itens-pedido w-100 mt-4 border bg-white p-3">
                            <div class="d-flex justify-content-between">
                                <h5>Itens do Pedido <small>({{pedido.items.length}})</small></h5>
                                <button type="button"
                                        class="btn btn-sm btn-outline-info"
                                        v-if="!data.item.import_info"
                                        @click="adicionarItemNoPedido(data.index)">
                                    Novo item
                                </button>
                            </div>

                            <hr class="my-2" />

                            <b-table ref="tableItensPedidos"
                                     :fields="fieldsItensPedido"
                                     :items="pedido.items">

                                <template #cell(botoes)="dataItem">
                                    <div class="text-center">
                                        <i type="button" class="fas fa-eye mr-3" @click="toggleItensDetails(dataItem.item)"></i>
                                        <i type="button" class="fas fa-trash" @click="pedido.items.splice(dataItem.index, 1)" v-if="!data.item.import_info"></i>
                                    </div>
                                </template>

                                <template #row-details="itemDetail">
                                    <section id="container-edicao-itens-pedido" class="d-flex">
                                        <div class="p-2 overflow-auto" style="height: 300px; width: 30vw;">
                                            <div v-for="(item, index) in produtos"
                                                 :key="index"
                                                 class="border border-secondary p-1 mb-1">

                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <img :src="item.images[0]" class="mr-1" height="30" width="50" loading="lazy" />
                                                        <small>{{item.sku}} - {{item.name}}</small>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm">
                                                            <i v-if="item.sku === itemDetail.item.id" class="fas fa-check-square"></i>
                                                            <i v-else @click="!data.item.import_info && selecionarItemAoPedido(itemDetail, item)" class="far fa-square"></i>
                                                        </button>

                                                        <b-button v-if="item.variations"
                                                                  v-b-toggle="'collapse-variacoes-' + index"
                                                                  variant="light"
                                                                  size="sm"
                                                                  class="btn-sm">
                                                            ({{item.variations.length}})
                                                            <i type="button" class="fas fa-arrow-down"></i>
                                                        </b-button>
                                                    </div>
                                                </div>


                                                <b-collapse :id="'collapse-variacoes-' + index">
                                                    <div v-for="variacao in item.variations"
                                                         class="py-1 ml-1 pl-1 border-left">
                                                        <img :src="variacao.images[0]" class="mr-1" height="30" width="50" loading="lazy" />
                                                        <small>{{variacao.sku}}</small>
                                                        <button class="btn btn-sm p-1 ml-1">
                                                            <i v-if="variacao.sku === itemDetail.item.id" class="fas fa-check-square"></i>
                                                            <i v-else @click="!data.item.import_info && selecionarItemAoPedido(itemDetail, variacao, true)" class="far fa-square"></i>
                                                        </button>
                                                    </div>
                                                </b-collapse>

                                            </div>
                                        </div>

                                        <div class="d-flex flex-column ml-3">
                                            <label>
                                                SKU
                                                <input v-model="itemDetail.item.id" :disabled="data.item.import_info" />
                                            </label>
                                            <label>
                                                Quantidade
                                                <input type="number" v-model="itemDetail.item.qty" :disabled="data.item.import_info" />
                                            </label>
                                            <label>
                                                Preço Original
                                                <input type="number" v-model="itemDetail.item.original_price" :disabled="data.item.import_info" />
                                            </label>
                                            <label>
                                                Preço de Venda
                                                <input type="number" v-model="itemDetail.item.special_price" :disabled="data.item.import_info" />
                                            </label>
                                        </div>
                                    </section>

                                </template>

                            </b-table>
                        </div>
                    </div>
                </section>
            </template>

        </b-table>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        props: {
            pedidos: {
                type: Array,
            },
            produtos: {
                type: Array
            }
        },
        data() {
            return {
                fieldsPedidos: [
                    { key: 'channel', label: 'Canal' },
                    { key: 'import_info.remote_code', label: 'Código' },
                    { key: 'imported_at', label: 'Data' },
                    { key: 'items[0].product_id', label: 'SKUs' },
                    { key: 'customer.name', label: 'Cliente' },
                    { key: 'total_ordered', label: 'Valor' },
                    { key: 'status.label', label: 'Status' },
                    { key: 'sync_status', label: 'Sincronização' },
                    { key: 'botoes', label: ' '}
                ],
                fieldsItensPedido: [
                    { key: 'id', label: 'SKU' },
                    { key: 'name', label: 'Nome' },
                    { key: 'detail', label: 'Detalhe' },
                    { key: 'qty', label: 'Quantidade' },
                    { key: 'original_price', label: 'Preço Original' },
                    { key: 'special_price', label: 'Preço de Venda' },
                    { key: 'botoes', label: ' ' },
                ],
                pedido: {
                    channel: '',
                    remote_code: '',
                    delivery_contract_type: '',
                    shipping_method: '',
                    shipping_carrier: '',
                    shipping_calculation_type: '',
                    estimated_delivery: '',
                    shipping_cost: 0,
                    interest: 0,
                    discount: 0,
                    customer: {
                        name: '',
                        email: '',
                        date_of_birth: '',
                        gender: '',
                        vat_number: '',
                        phones: ['']
                    },
                    billing_address: {
                        street: '',
                        number: '',
                        detail: '',
                        neighborhood: '',
                        city: '',
                        region: '',
                        country: '',
                        postcode: ''
                    },
                    shipping_address: {
                        street: '',
                        number: '',
                        detail: '',
                        neighborhood: '',
                        city: '',
                        region: '',
                        country: '',
                        postcode: ''
                    },
                    items: [],
                    //pick_ups: [{
                    //    store_code: '',
                    //    items: [{
                    //        qty: 0,
                    //        product_id: '',
                    //        id: ''
                    //    }],
                    //    agent: {
                    //        vat_number: '',
                    //        name: ''
                    //    }
                    //}],
                    //payments: [{
                    //    method: '',
                    //    description: '',
                    //    parcels: 0,
                    //    value: 0
                    //}],
                },
                statusPedidoAlterado: '',
                listaStatusPedido: [
                    {
                        label: 'Faturado',
                        code: 'order_invoiced',
                        url: 'invoice'
                    },
                    {
                        label: 'Enviado',
                        code: 'order_shipped',
                        url: 'shipments'
                    },
                    {
                        label: 'Entregue ao cliente',
                        code: 'complete',
                        url: 'delivery'
                    },
                    {
                        label: 'Cancelado',
                        code: 'order_canceled',
                        url: 'cancel'
                    },
                    {
                        label: 'Exceção de Entrega',
                        code: 'shipment_exception',
                        url: 'shipment_exception'
                    },
                ],
                dadosStatus: {
                    status: '',
                    invoice: { // Faturamento
                        key: '',
                        volume_qty: 1,
                        issue_date: '0001-01-01T00:00',
                    }, 
                    shipments: { // Enviado
                        code: '',
                        delivered_carrier_date: '0001-01-01T00:00',
                        items: [],
                        track: {
                            code: '',
                            carrier: '',
                            method: '',
                            url: ''
                        }
                    },
                    delivered_date: '0001-01-01', // Entregue
                    shipment_exception: { // Excecao de entrega
                        occurrence_date: '0001-01-01T00:00',
                        observation: ''
                    }
                },
                buscadorPedidoFila: ''
            }
        },
        methods: {
            verArquivo(event) {
                console.log('arquivo: ', event)
            },
            toggleDetails(rowPedido) {
                if (rowPedido._showDetails) {
                    this.$set(rowPedido, '_showDetails', false)
                } else {
                    this.pedidos.forEach(item => {
                        this.$set(item, '_showDetails', false)
                    })

                    this.$nextTick(() => {
                        this.limparPedido();
                        this.pedido = rowPedido;
                        this.$set(rowPedido, '_showDetails', true)
                    })
                }
            },
            toggleItensDetails(rowItem) {
                if (rowItem._showDetails) {
                    this.$set(rowItem, '_showDetails', false)
                } else {
                    this.pedido.items.forEach(item => {
                        this.$set(rowItem, '_showDetails', false)
                    })

                    this.$nextTick(() => {
                        this.$set(rowItem, '_showDetails', true)
                    })
                }
            },
            adicionarPedido() {
                this.limparPedido()
                this.pedidos.push(this.pedido)
                this.toggleDetails(this.pedido);
            },
            adicionarItemNoPedido() {
                const novoItem = { id: '', qty: 0, original_price: 0, special_price: 0 }
                this.pedido.items.push(novoItem);
            },
            selecionarItemAoPedido(data, item, ehVariacao = false) {
                let itemPedido = {
                    id: item.sku,
                    qty: 0
                };

                if (ehVariacao) {
                    itemPedido.original_price = item.specifications.find(spec => spec.key === 'price').value;
                    itemPedido.special_price = item.specifications.find(spec => spec.key === 'promotional_price').value;

                } else {
                    itemPedido.original_price = item.price;
                    itemPedido.special_price = item.promotional_price;
                }

                Object.entries(data.item).forEach(prop => {
                    if (itemPedido.hasOwnProperty([prop[0]]) && prop[0] !== '_showDetails') {
                        this.$set(data.item, prop[0], itemPedido[prop[0]]);
                    } else {
                        if (prop[0] !== '_showDetails')
                            delete data.item[prop[0]];
                    }
                })
            },
            salvarPedido(pedido) {
                pedido.estimated_delivery = new Date(pedido.estimated_delivery).toISOString().slice(0, 10);
                pedido.customer.date_of_birth = new Date(pedido.customer.date_of_birth).toISOString().slice(0, 10);

                axios.post('https://localhost:5001/integracao-skyhub/pedido', { order: {...pedido} })
                    .then(() => {
                        console.log('pedido salvo: ', pedido)
                        //setTimeout(() => this.$emit('atualizaPedidos'), 2000)
                        //setTimeout(() => {
                        //    const novoPedidoAdicionado = this.pedidos.find(p => p.status.type === 'NEW')
                        //    axios.put(
                        //        `https://localhost:5001/integracao-skyhub/pedido/${novoPedidoAdicionado.code}/status/approval`,
                        //        { status: 'payment_received' }
                        //    ).then(res => {
                        //        if (res) {
                        //            //setTimeout(() => this.$emit('atualizaPedidos'), 1000)
                        //        }
                        //    })
                        //}, 8000)
                    })
                    .catch(err => console.log(err))

                this.toggleDetails(pedido);
            },
            atualizarStatusPedido() {
                if (!this.statusPedidoAlterado) {
                    alert('Selecione um status para atualizar');
                    return
                }

                let dataStatusPedido = {
                    status: this.statusPedidoAlterado
                };

                if (this.statusPedidoAlterado === 'order_invoiced') {
                    if (this.pedido.shipping_calculation_type === 'b2wentregadirect') {
                        this.atualizarStatusFaturadoDirect();
                        return
                    }

                    if (this.dadosStatus.invoice.key.length < 44) {
                        alert('Chave NF-e deve ter 44 digitos');
                        return
                    }

                    dataStatusPedido.invoice = { key: this.dadosStatus.invoice.key };
                }

                if (this.statusPedidoAlterado === 'order_shipped') {
                    if (!this.dadosStatus.shipments.track.code) {
                        alert('Código de Rastreio deve ser preenchido');
                        return
                    }

                    dataStatusPedido.shipment = this.dadosStatus.shipments;
                    dataStatusPedido.shipment.code = this.pedido.code;
                    dataStatusPedido.shipment.items = this.pedido.items.map(item => {
                        return {
                            sku: item.id,
                            qty: item.qty
                        }
                    })
                }

                if (this.statusPedidoAlterado === 'complete') {
                    if (!this.dadosStatus.delivered_date) {
                        alert('Data de entrega deve ser preenchido');
                        return
                    }

                    const dataFormatada = new Date(this.dadosStatus.delivered_date + 'T00:00:00').toLocaleDateString()
                    dataStatusPedido.delivered_date = dataFormatada;
                }

                if (this.statusPedidoAlterado === 'order_canceled'
                    && !confirm('Confirma a exclusão deste pedido?')) {
                        return
                }

                if (this.statusPedidoAlterado === 'shipment_exception') {
                    if (!this.dadosStatus.shipment_exception.occurrence_date || !this.dadosStatus.shipment_exception.observation) {
                        alert('Data de ocorrência e observação devem ser preenchidos')
                        return
                    }

                    dataStatusPedido.shipment_exception = this.dadosStatus.shipment_exception;
                }

                const urlPathStatus = this.listaStatusPedido.find(status => status.code === this.statusPedidoAlterado).url

                axios.put(
                    `https://localhost:5001/integracao-skyhub/pedido/${this.pedido.code}/status/${urlPathStatus}`,
                    dataStatusPedido
                )
                    .then(({ data }) => {
                        if (!data.data.success) {
                            const dadosResponse = JSON.parse(data.data.result.replace('/[\]/g', ''))
                            alert(dadosResponse?.error)
                            return
                        }
                        //setTimeout(() => { this.atualizarFilaPedido() }, 10000);
                })
                .catch(err => console.log(err))
            },
            atualizarStatusFaturadoDirect() {
                const arquivoNfeXml = this.$refs.arquivoFaturadoDirect?.files[0];
                if (!arquivoNfeXml || arquivoNfeXml?.type !== 'text/xml') {
                    alert('É necessario adicionar um arquivo NF-e em formato XML')
                    return
                }

                const formData = new FormData();
                formData.append('file', arquivoNfeXml)
                formData.append('issue_date', this.dadosStatus.invoice.issue_date)
                formData.append('volume_qty', this.dadosStatus.invoice.volume_qty)

                axios.post(
                    `https://localhost:5001/integracao-skyhub/pedido/${this.pedido.code}/status/invoice-direct`,
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                )
                .then(res => {
                    if (!data.data.success) {
                        const dadosResponse = JSON.parse(data.data.result.replace('/[\]/g', ''))
                        alert(dadosResponse?.error)
                        return
                    }
                })
                .catch(err => console.log(err))
            },
            atualizarFilaPedido() {
                axios.get('https://localhost:5001/integracao-skyhub/pedido/fila')
                    .then(res => {
                        if (res.data) {
                            res.data.shipping_calculation_type = res.data.calculation_type

                            const indicePedidoFila = this.pedidos.findIndex(pedido => pedido.import_info.remote_code === res.data.import_info.remote_code)

                            this.pedidos[indicePedidoFila] = res.data
                            this.$refs.tabelaPedidos.refresh();

                            console.log('atualiza pedido fila: ', indicePedidoFila, this.pedidos[indicePedidoFila])
                        }
                    }) 
            },
            limparPedido() {
                this.pedido = {
                    channel: 'Marketplace',
                    remote_code: '',
                    delivery_contract_type: '',
                    shipping_method: 'B2W',
                    shipping_carrier: 'direct',
                    shipping_calculation_type: 'b2wentregacorreio',
                    estimated_delivery: '2022-01-20',
                    shipping_cost: 3.75,
                    interest: 0.88,
                    discount: 0.25,
                    customer: {
                        name: 'luiz falavinha',
                        email: 'luiz@email.com',
                        date_of_birth: '1997-04-08',
                        gender: 'male',
                        vat_number: '46973270000',
                        phones: ['14997902484']
                    },
                    billing_address: {
                        street: 'rua billing',
                        number: '129',
                        detail: 'detalhe billing',
                        neighborhood: 'neightborhood billing',
                        city: 'iac billing',
                        region: 'sp',
                        country: 'BR',
                        postcode: '123123123'
                    },
                    shipping_address: {
                        street: 'rua shipping',
                        number: '250',
                        detail: 'detalhe shipping',
                        neighborhood: 'neightborhood shipping',
                        city: 'iac shipping',
                        region: 'sp',
                        country: 'BR',
                        postcode: '234234234'
                    },
                    items: [
                        {
                            id: 'PRODCEL12',
                            qty: 3,
                            original_price: 1500.00,
                            special_price: 1400.00
                        },
                    ]
                    //pick_ups: [{
                    //    store_code: '',
                    //    items: [{
                    //        qty: 0,
                    //        product_id: '',
                    //        id: ''
                    //    }],
                    //    agent: {
                    //        vat_number: '',
                    //        name: ''
                    //    }
                    //}],
                    //payments: [{
                    //    method: '',
                    //    description: '',
                    //    parcels: 0,
                    //    value: 0
                    //}],
                }
            },
        },
        //mounted() {
        //    this.buscadorPedidoFila = setInterval(() => {
        //        this.atualizarFilaPedido()
        //    }, 5000)
        //},
        //beforeDestroy() {
        //    clearInterval(this.buscadorPedidoFila);
        //}
    }
</script>

