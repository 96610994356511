<template>
    <div class="modal-skyhub-backdrop">
        <div class="modal-skyhub">

            <b-alert v-model="alerta.show"
                     :variant="alerta.variante"
                     dismissible>
                {{ alerta.mensagem }}
            </b-alert>

            <section id="modal-skyhub-header" class="modal-header align-items-center p-2">
                <h5 class="font-weight-bold m-0">SkyHub - Integra��o B2W</h5>
                <button type="button"
                        class="btn-close"
                        @click="$emit('close')"
                        aria-label="Close modal">
                    x
                </button>
            </section>

            <div class="flex">
                <div class="modal-skyhub-menu bg-dark" style="opacity: .75;">
                    <button v-for="botao in listaAbasBotoes"
                            @click="abaMenuAberto = botao"
                            :class="{ 'bg-info': abaMenuAberto === botao }"
                            class="btn text-uppercase button-menu">
                        {{ botao }}
                    </button>
                </div>

                <div id="modal-skyhub-body" class="modal-body">
                    <section v-if="abaMenuAberto == 'produtos'">

                        <TabelaProdutosSkyHub :totalProdutos="listaProdutos.total"
                                              :produtos="listaProdutos.products"
                                              :categorias="listaCategoriasProdutos"
                                              :atributos="listaAtributos.attributes"
                                              @atualizarProdutos="buscarDadosProdutosSkyHub"
                                              @salvarProduto="salvarProduto"
                                              @salvarVariacao="salvarVariacao"
                                              @removerProduto="removerProduto"
                                             />

                    </section>

                    <section v-if="abaMenuAberto == 'pedidos'">
                        <TabelaPedidosSkyHub 
                            :pedidos="listaPedidos"
                            :produtos="listaProdutos.products"
                            @atualizaPedidos="buscarDadosPedidosSkyHub"
                        />
                    </section>

                    <section v-if="abaMenuAberto == 'etiquetas'">
                        <TabelasEtiquetasSkyHub />
                    </section>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import sharkHubServices from "@/assets/js/services/SharkHubServices";

    import TabelaProdutosSkyHub from '@/components/skyhub/TabelaProdutosSkyHub.vue'
    import TabelaPedidosSkyHub from '@/components/skyhub/TabelaPedidosSkyHub.vue'
    import TabelasEtiquetasSkyHub from '@/components/skyhub/TabelasEtiquetasSkyHub.vue'

    export default {
        name: 'ModalPrincipalSkyHub',
        components: {
            TabelaProdutosSkyHub,
            TabelaPedidosSkyHub,
            TabelasEtiquetasSkyHub
        },
        data: function () {
            return {
                abaMenuAberto: 'produtos',
                listaAbasBotoes: ['produtos', 'pedidos', 'etiquetas'],

                alerta: {
                    show: false,
                    variante: '',
                    mensagem: '',
                },


                itemsProdutoExibicao: [
                    {
                        key: 'images',
                        label: 'Imagem',
                        class: 'p-1 width-60'
                    },
                    {
                        key: 'name',
                        label: 'Nome',
                        class: 'p-1'
                    },
                    {
                        key: 'sku',
                        label: 'SKU',
                        class: 'p-1'
                    },
                    {
                        key: 'description',
                        label: 'Descri��o',
                        class: 'p-1'
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        class: 'p-1'
                    },
                    {
                        key: 'qty',
                        label: 'Qtde',
                        class: 'p-1'
                    },
                    {
                        key: 'price',
                        label: 'Pre�o',
                        class: 'p-1'
                    },
                    {
                        key: 'botoes',
                        label: '',
                        class: 'p-1 align-middle'
                    }
                ],
                itemsVariacaoProdutoPadrao: [
                    {
                        key: 'images',
                        label: 'Imagens',
                    },
                    {
                        key: 'sku',
                        label: 'SKU Varia��o',
                    },
                    {
                        key: 'ean',
                        label: 'EAN',
                    },
                    {
                        key: 'price',
                        label: 'Pre�o',
                    },
                    {
                        key: 'promotional_price',
                        label: 'Pre�o Promocional',
                    },
                    {
                        key: 'qty',
                        label: 'Qtde',
                    },
                ],
                listaProdutos: {},
                listaCategoriasProdutos: [],
                listaAtributos: [],
                variacoesProdutoVisivel: false,
                itemsVariacoesProdutoCompleto: [],
                edicaoProdutoHabilitado: false,
                listaPedidos: []
            }
        },
        methods: {
            buscarDadosProdutosSkyHub: async function () {
                let stringEncodePaginacao = ''
                if (this.listaProdutos.next)
                    stringEncodePaginacao = new URL(this.listaProdutos.next).searchParams.get('cursor')

                let produtosCarregados = await sharkHubServices.getListaProdutosSkyhub(stringEncodePaginacao);
                produtosCarregados.products?.forEach(p => { p['skuInicial'] = p.sku })

                if (!this.listaProdutos.products) this.listaProdutos.products = []
                this.listaProdutos = {
                    products: [...this.listaProdutos?.products, ...produtosCarregados.products],
                    total: produtosCarregados.total,
                    next: produtosCarregados.next
                }

                if (stringEncodePaginacao)
                    return

                this.listaCategoriasProdutos = await sharkHubServices.getListaCategoriasProdutosSkyhub();
                this.listaAtributos = await sharkHubServices.getListaAtributosSkyHub();
            },
            buscarDadosPedidosSkyHub: async function () {
                let pedidos = await sharkHubServices.getListaPedidosSkyHub();
                this.listaPedidos = pedidos.orders.map(pedido => {
                    return { ...pedido, shipping_calculation_type: pedido.calculation_type }
                })
                console.log('pedidos: ', this.listaPedidos);
            },
            salvarProduto: async function (produto) {
                if (produto.imagensAdicionadas.length > 0) {
                    let imagensSalva = await this.uploadImagens(produto.imagensAdicionadas);
                    if (imagensSalva.Sucesso) {
                        produto.imagensAdicionadas.forEach(obj => {
                            let indiceImagemSalva = produto.images.findIndex(name => name === obj.urlBlob);
                            produto.images[indiceImagemSalva] = `${document.location.origin}${imagensSalva.Dados.diretorio}${obj.arquivo.name}`
                        })
                        delete produto.imagensAdicionadas;
                    }
                }

                if (produto.variations?.length) {
                    let variacoesProduto = produto.variations

                    variacoesProduto    //salvar imagens adicionadas
                        .forEach((variacao, index) => {
                            if (variacao.imagensAdicionadas?.length) {
                                this.salvarImagensAdicionadas(variacao.images, variacao.imagensAdicionadas, (imagensSalvas) => {
                                    variacoesProduto[index].images = imagensSalvas
                                });
                            }
                        })

                    produto = { ...produto, variations: variacoesProduto }
                }

                setTimeout(() => {
                    if (produto.novoProduto) {
                        sharkHubServices.adicionarProdutoSkyHub({ product: { ...produto } })
                            .then(res => {
                                this.listaProdutos.total += 1;
                                alert(res);
                            })
                    } else {
                        sharkHubServices.editarProdutoSkyHub({ product: { ...produto } })
                            .then(res => {
                                alert(res);
                                this.buscarDadosProdutosSkyHub()
                            })
                    }
                }, 1000)
            },
            salvarVariacao: async function (variacaoProduto) {
                if (variacaoProduto.imagensAdicionadas.length > 0) {
                    let imagensSalva = await this.uploadImagens(variacaoProduto.imagensAdicionadas);
                    if (imagensSalva.Sucesso) {
                        variacaoProduto.imagensAdicionadas.forEach(obj => {
                            let indiceImagemSalva = variacaoProduto.images.findIndex(name => name === obj.urlBlob);
                            variacaoProduto.images[indiceImagemSalva] = `${document.location.origin}${imagensSalva.Dados.diretorio}${obj.arquivo.name}`
                        })
                        delete variacaoProduto.imagensAdicionadas;
                    }
                }

                sharkHubServices.salvarVariavelProdutoSkyHub({ variation: { ...variacaoProduto } })
                    .then(res => {
                        this.buscarDadosProdutosSkyHub();
                        console.log('response variavel: ', variacaoProduto, res)
                    });
            },
            salvarImagensAdicionadas: function (imagens, imagensAdicionadas, callback) {
                imagensAdicionadas = imagensAdicionadas.filter(imgAdicionada => imagens.includes(imgAdicionada.urlBlob))
                this.uploadImagens(imagensAdicionadas)
                    .then(imagensSalvas => {
                        if (imagensSalvas.Sucesso) {
                            imagensAdicionadas.forEach(obj => {
                                let indiceImagemSalva = imagens.findIndex(name => name === obj.urlBlob);
                                imagens[indiceImagemSalva] = `${document.location.origin}${imagensSalvas.Dados.diretorio}${obj.arquivo.name}`
                            })
                            return callback(imagens);
                        }
                        return callback(null);
                    })
            },
            uploadImagens: async function  (imagens) {
                let formDataImagens = new FormData();
                imagens.forEach(img => {
                    formDataImagens.append('imagens', img.arquivo);
                })

                return sharkHubServices.uploadImagensSkyHub(formDataImagens);
            },
            removerProduto(sku) {
                sharkHubServices.removerProdutoSkyHub(sku)
                    .then(res => {
                        console.log('response exclusao: ', res)
                        this.listaProdutos.products = this.listaProdutos.products.filter(produto => produto.sku !== sku)
                        this.listaProdutos.total -= 1;
                        setTimeout(() => { this.buscarDadosProdutosSkyHub() }, 2000);
                    });
            },
            showAlert(success, message) {
                this.alerta.show = true;
                this.alerta.variante = success ? 'success' : 'danger'
                this.alerta.mensagem = message;
            }
        },
        
        created() {
            this.buscarDadosProdutosSkyHub();
            this.buscarDadosPedidosSkyHub();
        }
    };
</script>

<style scoped>
    .modal-skyhub-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        z-index: 1000;
        padding: 4%;
    }

    .modal-skyhub {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        width: 100%;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-body {
        height: calc(75vh);
        padding: 10px;
    }

    .btn-close {
        border: none;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .button-menu {
        color: white;
        font-size: 14px;
        min-width: 60px;
        width: 8rem;
        text-align: center;
        border-radius: 0;
    }

    .formulario-edicao-produto {
        display: flex;
    }


    .formulario-edicao-produto input, select, textarea {
        width: 25vw;
    }
</style>

<style>
    .width-60 {
        width: 60px;
    }
</style>
