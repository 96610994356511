<template>
    <div id="tabelas-etiquetas-skyhub" class="pb-2">
        <b-card header-tag="nav" class="h-100">
            <template #header>
                <b-nav card-header tabs>
                    <b-nav-item :active="abaSelecionada === 'aptosAgrupamento'" @click="abrirAba('aptosAgrupamento')">Pedidos para Agrupamento</b-nav-item>
                    <b-nav-item :active="abaSelecionada === 'plp'" @click="abrirAba('plp')">Pré Lista de Postagem (PLP)</b-nav-item>
                    <b-nav-item :active="abaSelecionada === 'aptosColeta'" @click="abrirAba('aptosColeta')">Solicitar Coleta</b-nav-item>
                </b-nav>
            </template>

            <section v-if="abaSelecionada === 'aptosAgrupamento'">
                <b-table bordered
                         responsive
                         small
                         :fields="fieldsPedidosAptos"
                         :items="pedidosAptos">

                    <template #head(checkbox)="{item}">
                        <input type="checkbox" @click="selecionarTodosPedidos" />
                    </template>

                    <template #cell(checkbox)="{item}">
                        <input type="checkbox" :value="item.code" v-model="pedidosSelecionados" />
                    </template>
                </b-table>

                <b-button class="mt-1" size="sm" variant="primary" @click="agruparPedidosEmPlp">Agrupar pedidos em uma PLP</b-button>
            </section>

            <section v-if="abaSelecionada === 'plp'">
                <b-table bordered
                         responsive
                         small
                         :fields="fieldsListaPlp"
                         :items="listaPlp">

                    <template #cell(orders)="{item}">
                        {{ item.orders.map(order => order.code).join(', ') }}
                    </template>

                    <template #cell(acoes)="{item}">
                        <b-button variant="primary" size="sm" class="mr-1"><i type="button" class="fas fa-eye" @click="recuperarPlp(item.id)"></i></b-button>
                        <b-button variant="secondary" size="sm"><i type="button" class="fas fa-trash" @click="desagruparPlp(item.id)"></i></b-button>
                    </template>

                </b-table>
            </section>

            <section v-if="abaSelecionada === 'aptosColeta'">
                <b-table bordered
                         responsive
                         small
                         :fields="fieldsPedidosColeta"
                         :items="pedidosAptosColeta">

                    <template #head(checkbox)="{item}">
                        <input type="checkbox" @click="selecionarTodosPedidos" />
                    </template>

                    <template #cell(checkbox)="{item}">
                        <input type="checkbox" :value="item.code" v-model="pedidosAptosColetaSelecionados" />
                    </template>

                </b-table>

                <b-button class="mt-1" size="sm" variant="primary" @click="abrirModalConfirmacaoColeta">Solicitar Coleta</b-button>
            </section>

            <b-overlay :show="showOverlay" no-wrap>
            </b-overlay>

        </b-card>

        <b-modal 
            id="modalConfirmaColeta" 
            size="md" 
            title="Solicitar Coleta pela Transportadora"
            cancel-title="Cancelar"
            ok-title="Confirmar"
            @ok="solicitarColetaPedidos"
        >
            <p>Deseja que a transportadora retire as seguintes entregas?</p>
            <ul>
                <li v-for="pedido in pedidosAptosColetaSelecionados" :key="pedido">{{ pedido }}</li>
            </ul>
            <p>Ao confirmar, a transportadora será notificada e enviará um veículo para realizar a coleta dessas entregas.</p>
        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'TabelaEtiquetasSkyHub',
        data() {
            return {
                showOverlay: false,
                abaSelecionada: 'aptosAgrupamento',
                fieldsPedidosAptos: [
                    { key: 'checkbox', label: ' ', thStyle: 'width: 20px', tdClass: 'text-center' },
                    { key: 'code', label: 'Pedido', thStyle: 'width: 150px' },
                    { key: 'customer', label: 'Cliente' },
                    { key: 'value', label: 'Valor', thStyle: 'width: 150px' },
                    { key: 'shipping', label: 'Expedição' },
                ],
                fieldsListaPlp: [
                    { key: 'id', label: 'Id', thStyle: 'width: 100px' },
                    { key: 'expiration_date', label: 'Expira em', thStyle: 'width: 150px' },
                    { key: 'orders', label: 'Pedidos Agrupados' },
                    { key: 'acoes', label: 'Ações', thStyle: 'width: 100px', tdClass: 'text-center' },
                ],
                fieldsPedidosColeta: [
                    { key: 'checkbox', label: ' ', thStyle: 'width: 20px', tdClass: 'text-center' },
                    { key: 'code', label: 'Pedido', thStyle: 'width: 150px' },
                    { key: 'customer', label: 'Cliente' },
                    { key: 'value', label: 'Valor', thStyle: 'width: 150px' }
                ],
                pedidosAptos: [],
                listaPlp: [],
                pedidosAptosColeta: [],

                pedidosSelecionados: [],
                pedidosAptosColetaSelecionados: [],
            }
        },
        methods: {
            converterDecimalEmReais: (valorDecimal) => valorDecimal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            abrirAba(aba) {
                this.showOverlay = true
                this.abaSelecionada = aba;
                if (aba === 'aptosAgrupamento') this.buscarPedidosAptosAgrupamento();
                if (aba === 'plp') this.buscarListaPlp();
                if (aba === 'aptosColeta') this.buscarPedidosAptosColeta();
                this.showOverlay = false
            },
            buscarPedidosAptosAgrupamento() {
                axios.get('https://localhost:5001/integracao-skyhub/plp/aptas-agrupamento')
                    .then(res => {
                        this.pedidosAptos = res.data.orders
                            .map(p => {
                                return { ...p, value: this.converterDecimalEmReais(p.value) }
                            });
                })
            },
            buscarListaPlp() {
                axios.get('https://localhost:5001/integracao-skyhub/plp/agrupadas')
                    .then(res => {
                        this.listaPlp = res.data.plp;
                    })
            },
            buscarPedidosAptosColeta() {
                axios.get('https://localhost:5001/integracao-skyhub/plp/aptos-coleta')
                    .then(res => {
                        this.pedidosAptosColeta = res.data.orders
                            .map(p => {
                                return { ...p, value: this.converterDecimalEmReais(p.value) }
                            });
                    })
            },
            selecionarTodosPedidos(event) {
                const checkSelecionado = event.target.checked;
                if (this.abaSelecionada === 'aptosAgrupamento') {
                    if (checkSelecionado) {
                        this.pedidosSelecionados = this.pedidosAptos.map(p => p.code);
                        return
                    }
                    this.pedidosSelecionados = []
                }

                if (this.abaSelecionada === 'aptosColeta') {
                    if (checkSelecionado) {
                        this.pedidosAptosColetaSelecionados = this.pedidosAptosColeta.map(p => p.code);
                        return
                    }
                    this.pedidosAptosColetaSelecionados = []
                }
            },
            agruparPedidosEmPlp() {
                if (this.pedidosSelecionados.length) {
                    this.showOverlay = true
                    axios.post(
                        'https://localhost:5001/integracao-skyhub/plp/agrupar',
                        { order_remote_codes: this.pedidosSelecionados }
                    )
                    .then(res => {
                        this.showOverlay = false
                        if (res.data?.success) {
                            this.pedidosAptos = this.pedidosAptos.filter(pedApto => !this.pedidosSelecionados.includes(pedApto.code))
                            this.pedidosSelecionados = []
                            alert("Pedidos agrupados com sucesso!");
                        }
                    })
                }
            },
            desagruparPlp(plpId) {
                if (!window.confirm(`Deseja realmente desagrupar a PLP ${plpId}?`))
                    return

                this.showOverlay = true
                axios.delete(`https://localhost:5001/integracao-skyhub/plp/${plpId}/desagrupar`)
                    .then(res => {
                        this.showOverlay = false
                        if (res.data.message?.includes('sucesso')) {
                            this.listaPlp = this.listaPlp.filter(plp => plp.id !== plpId)
                        }
                    })
            },
            recuperarPlp(plpId) {
                this.showOverlay = true
                axios.get(`https://localhost:5001/integracao-skyhub/plp/${plpId}/recuperar`)
                    .then(res => {
                        this.showOverlay = false
                        if (res.data.success) {
                            this.downloadEtiquetasEmPdf(res.data.data, plpId)
                            return
                        }
                        alert("Houve um erro ao recuperar PLP " + plpId);
                    })
            },
            downloadEtiquetasEmPdf(pdfBase64String, plpId) {
                const linkSource = `data:application/pdf;base64,${pdfBase64String}`;
                const downloadLink = document.createElement("a");
                const fileName = `PLP-${plpId}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                downloadLink.remove();
            },
            abrirModalConfirmacaoColeta() {
                if (!this.pedidosAptosColetaSelecionados.length) {
                    alert("Nenhum pedido foi selecionado para coleta!")
                    return
                }

                this.$bvModal.show('modalConfirmaColeta')
            },
            solicitarColetaPedidos() {
                this.showOverlay = true;
                axios.post(
                    `https://localhost:5001/integracao-skyhub/plp/confirmar-coleta`,
                    { order_codes: this.pedidosAptosColetaSelecionados }
                )
                .then(res => {
                    this.showOverlay = false;
                    if (res.data.result === 'erro') {
                        alert(`ERRO: ${res.data.message}`)
                        return
                    }

                    if (res.data.message?.includes('sucesso')) {
                        this.pedidosAptosColeta = this.pedidosAptosColeta.filter(pedColeta => !this.pedidosAptosColetaSelecionados.includes(pedColeta.code));
                        this.pedidosAptosColetaSelecionados = [];
                    }
                })
            }
        },
        mounted() {
            this.showOverlay = true;
            this.buscarPedidosAptosAgrupamento();
            this.buscarListaPlp();
            this.buscarPedidosAptosColeta();
            this.showOverlay = false;
        }
    }
</script>

<style scoped>

</style>
